import React from 'react'
import { BooleanField, Datagrid, List, ListProps, NullableBooleanInput, NumberField, TextField } from 'react-admin'

import CustomLinkField from 'common/CustomLinkField'
import rowStyle from './rowStyle'
import CustomPagination from 'common/CustomPagination'
import { CompanyRecord } from 'api/company'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'

const CompanyList = (props: ListProps<CompanyRecord>) => {
  return <List {...props} perPage={50} sort={{ field: 'name', order: 'ASC' }}
    filters={[
      <OutlinedSearchInput source="q" alwaysOn label="Name"/>,
      <OutlinedSearchInput source='country' alwaysOn label="Country" />,
      <NullableBooleanInput source="ngo" className='custom-array-input' alwaysOn label="Charity" />,
      <NullableBooleanInput source="portalPropertiesOverridden" className='custom-array-input' alwaysOn label="Portal" />
    ]} pagination={<CustomPagination />}>
    <Datagrid rowStyle={(row) => rowStyle(row)} bulkActionButtons={false}>
      <CustomLinkField source="name" label={'Name'} sortBy='name' basePath='companies' show targetFieldId='id' />
      <TextField source='country' label='Country' sortBy='country' />
      <NumberField source='membersCount' label={'Members'} sortBy='count' />
      <BooleanField source='ngo' label='Charity' sortable={false} />
      <BooleanField source='portalPropertiesOverridden' label='Portal' sortable={false} />
    </Datagrid>
  </List>
}

export default React.memo(CompanyList)