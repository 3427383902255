import React from 'react'
import { useTranslate, Show, Link, useRecordContext, useNotify } from 'react-admin'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Divider, styled, Tooltip } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import JobShowActions from './JobShowActions'
import { ExtendedJobRecord } from 'api/jobs'
import JobShowDates from './jobShow/JobShowDates'
import JobQuoteDates from './jobShow/JobQuoteDates'
import JobShowStages from './jobShow/JobShowStages'
import JobShowComments from './jobShow/JobShowComments'
import './jobShow/arrow.css'
import SmallButton from 'components/SmallButton'
import { Footer, GAP, NormText, Container, NoSel, CARD_WIDTH } from './jobShow/Common'
import SideCard from 'components/SideCard'
import UserService from 'UserService'

export const FixedPricingIcon = styled(LockOutlinedIcon)({
  backgroundColor: "#f8f8f8",
  color: "#898989",
  height: "18px",

  ':hover': {
    backgroundColor: "#f4f4f4",
    color: "#606060"
  }
})

const JobShow = () => {
  return <Show title={<VisitorTitle />} component="div" sx={{ bgcolor: '#FAFAFA', paddingLeft: '4%', paddingRight: '1%' }}>
    <VisitorForm />
  </Show>
}

const JobInfo = () => {
  const translate = useTranslate()
  const record = useRecordContext<ExtendedJobRecord>()
  const notify = useNotify()

  const shouldSeeFeeOrProfit = UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_COMMUNITY_MANAGER', 'ROLE_PLATFORM_MANAGER'])

  const handleFilesCopy = () => {
    if (!record) return
    const files = record?.jobSections.map(({ name }) => name)

    // Create a new textarea element and set its value to the text to be copied
    const textarea = document.createElement("textarea")
    textarea.value = files.join('\n')

    // Add the textarea to the page
    document.body.appendChild(textarea)

    // Select the text in the textarea and copy it to the clipboard
    textarea.select()
    document.execCommand("copy")

    notify('Filenames copied to clipboard', { type: 'success' })

    // Remove the textarea from the page
    document.body.removeChild(textarea)
  }

  const GetPricesVariants = ({ includeFeeOrProfit: includeFeeOrProfit }: {includeFeeOrProfit: boolean}) => {
    const isProfitable = record?.profit>=0
    const PositiveProfit = styled(NormText)({
      color: '#33C26C'
    })
    const NegativeProfit = styled(NormText)({
      color: '#DB474C'
    })
    const CostBox = ({ width } : {width: string}) => {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' width={width}>
          {record?.pricingRuleType == 'FIXED' &&
            <Tooltip title="Fixed pricing" placement="top" arrow>
              <FixedPricingIcon />
            </Tooltip>}
          <NormText> <NoSel> <b>Cost: </b> </NoSel>€{record?.cost?.toFixed(2)} </NormText>
        </Box>
      )
    }
    const CostWithProfitBox = ({ width } : {width: string}) => {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' width={width}>
          {record?.pricingRuleType == 'FIXED' &&
            <Tooltip title="Fixed pricing" placement="top" arrow>
              <FixedPricingIcon />
            </Tooltip>}
          <NormText style={{ marginRight: '5px' }}> <NoSel> <b>Cost: </b> </NoSel>€{record?.cost?.toFixed(2)} </NormText>
          <Tooltip title="Profit" placement="top" arrow>
            {isProfitable ? 
              <PositiveProfit>+€{Math.abs(record?.profit)?.toFixed(2)}</PositiveProfit> : 
              <NegativeProfit>-€{Math.abs(record?.profit)?.toFixed(2)}</NegativeProfit>}
          </Tooltip>
        </Box>
      )
    }
    const FeeBox = ({ width } : {width: string}) => {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' width={width}>
          <NormText><NoSel><b>Fee: </b></NoSel>€{record?.exfluencyFee?.toFixed(2)}</NormText>
        </Box>
      )
    }
    
    if (includeFeeOrProfit) {
      return (
        <>
          {record?.pricingRuleType == 'FIXED' && <CostWithProfitBox width="100%"/>}
          {record?.pricingRuleType == 'DYNAMIC' && <>
            <CostBox width="50%"/>
            <FeeBox width="50%"/>
          </>}
        </>
      )
    }
    return (
      <CostBox width="100%"/>
    )
  }

  return <>
    <Box display='flex' gap={GAP} flexWrap='wrap' alignItems='flex-start'>
      <SideCard width={CARD_WIDTH}>
        <Box padding='30px 25px 20px 35px'>
          <NormText>
            <NoSel><b>Name:</b></NoSel> {record?.name}
          </NormText>
        </Box>
        <Divider />
        <Container>
          <div>
            <NormText>
              <b>Source Language:</b> {translate(`dict.lang.${record?.sourceLang}`)}
            </NormText>
            <NormText>
              <b>Target Language:</b> {translate(`dict.lang.${record?.targetLang}`)}
            </NormText>
          </div>
          <div>
            <NormText>
              <b>Source Words:</b> {record?.wordCount}
            </NormText>
            <NormText>
              <b>Target Words:</b> {record?.immediateTranslationWordCount}
            </NormText>
          </div>
          <div>
            <NormText>
              <b>Assets:</b> {record?.assetsCount}
            </NormText>
            <NormText>
              <b>Recycled Assets:</b> {record?.recycledAssetsCount}
            </NormText>
          </div>
          <NormText>
            <b>Files:</b> {record?.jobSections.length} <SmallButton onClick={handleFilesCopy}>Copy Details</SmallButton> {/* TODO: handle click */}
          </NormText>
        </Container>
        <Divider />
        <Container>
          <div>
            <NormText>
              <NoSel><b>External ID: </b></NoSel>{record?.externalProjectId}
            </NormText>
            <NormText>
              <NoSel><b>ID: </b></NoSel>{record?.id}
            </NormText>
          </div>
          <div>
            <NormText>
              <b>Type</b> {translate(`job.type.${record?.type}`)}
            </NormText>
            <NormText>
              <b>Genre:</b> {record?.genre}
            </NormText>
            <NormText>
              <b>Taxonomy:</b> {record?.taxonomy.join(', ')}
            </NormText>
          </div>
          <div>
            <NormText >
              <b>Requester:</b> <Link to={`/users/${record?.requesterId}/show`}>{record?.requester}</Link>
            </NormText>
            <NormText >
              <b>Company:</b> {record?.chargedCompany}
            </NormText>
            <NormText>
              <b>Gated Communities:</b> {record?.visibility.gatedCommunities.join(', ')}
            </NormText>
          </div>
        </Container>
        <Footer>
          <GetPricesVariants includeFeeOrProfit={shouldSeeFeeOrProfit} />
        </Footer>
      </SideCard>
      <Box display='flex' gap={GAP} flexDirection='column' flexWrap='wrap' alignItems='flex-start'>
        <JobShowDates />
        <JobQuoteDates />
        <JobShowStages />
      </Box>
    </Box>
    <JobShowComments />
  </>
}

const VisitorTitle = () => {
  const record = useRecordContext<ExtendedJobRecord>()
  return record ? <span>{record?.name}</span> : null
}

const VisitorForm = () => {
  return <Box display={{ md: 'block', lg: 'flex' }} flexDirection='column' gap={GAP} flexWrap='wrap' paddingBottom='30px' paddingTop='50px' alignItems='flex-start'>
    <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
        Project Information
    </Typography>
    <JobShowActions />
    <JobInfo />
  </Box>
}

export default JobShow
