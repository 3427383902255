import { Box } from '@mui/material'
import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

import { secondsToReadableUnits } from 'utils/helpers'

const CustomSecondsToReadabletimeField = ({ source }: FieldProps) => {
  const record = useRecordContext()
  return (record && source) ? <Box sx={{ textAlign: 'left' }}>
    <span>{secondsToReadableUnits(record[source])}</span>
  </Box> : null
}

export default CustomSecondsToReadabletimeField