import axios from "axios"

import { AssignmentStatus, Currency, downloadFile, Genre, JobStatus, StageJobSection, StageStatus, StageType } from "./common"
import { RCASmallInfo } from "./rca"
import { Comment } from 'api/common'

export interface AdminJobDashboardRecord {
  id: string,
  type: JobType,
  uploadDate: string,
  dueDate: string,
  requesterId: string,
  requester: string,
  name: string,
  wordCount: number,
  assetsCount: number,
  recycledAssetsCount: number,
  sourceLang: string,
  targetLang: string,
  genre: Genre,
  taxonomy: string[],
  status: JobStatus,
  deliveryDate?: string,
  externalProjectId?: string,
  exfluencyFee: number,
  cost: number,
  profit: number,
  visibility: JobVisibility,
  lastAccessibleAssignmentId: string,
  stages: string[],
  chargedCompany?: string,
  currentStage?: CurrentStage,
  pendingAcceptance: boolean,
  soonToBeLate: boolean,
  overdue: boolean,
  jobSections: JobSection[],
  reminderTicketId?: number,
  rcas: RCASmallInfo[]
}

export interface JobSection {
  id: string,
  name: string
}

export interface CurrentStage {
  id: string,
  type: StageType
}

export interface StageAttachment {
  fileId: string,
  fileName: string,
}

export interface ExtendedJobRecord {
  id: string,
  type: JobType,
  pricingRuleType: PricingRuleType,
  uploadDate: string,
  dueDate: string,
  requesterId: string,
  requester: string,
  name: string,
  wordCount: number,
  immediateTranslationWordCount: number | null,
  assetsCount: number,
  recycledAssetsCount: number,
  sourceLang: string,
  targetLang: string,
  genre: Genre,
  taxonomy: string[],
  status: JobStatus,
  deliveryDate?: string,
  externalProjectId?: string,
  exfluencyFee: number,
  cost: number,
  profit: number,
  visibility: JobVisibility,
  lastAccessibleAssignmentId: string,
  stages: SimpleStageRecord[],
  chargedCompany?: string,
  cancellable: boolean,
  jobSections: JobSection[],
  jobQuote: JobQuote | null
  rcas: RCASmallInfo[]
}

export interface JobQuote {
  calculationDate: string | null,
  acceptanceDate: string | null,
  rejectionDate: string | null
}

export interface SimpleStageRecord {
  id: string,
  type: StageType,
  status: StageStatus,
  dueDate: string,
  assignments: JobAssignmentEntity[],
  stageJobSections: StageJobSection[]
}

export interface JobAssignmentEntity {
  id: string,
  executorId: string,
  executor: string,
  status: AssignmentStatus,
  progress: number,
  uploadDate: string,
  dueDate: string,
  claimDate?: string,
  deliveryDate?: string,
  fiat: Currency,
  price: number,
  estimatedSeconds: number,
  workedSeconds: number,
  trustChainScore?: number
}

export type UserFileType = 'ATTACHMENT' | 'TCERT'
export interface UserFile {
  jobId: string,
  fileId: string,
  name: string,
  type: UserFileType,
}

export interface JobVisibility {
  openCommunity: boolean
  companies: string[]
  gatedCommunities: string[]
}

export type JobType = 'COMMERCIAL' | 'CHARITY' | 'EXAMINATION'

export type PricingRuleType = 'DYNAMIC' | 'FIXED'

export interface NewReminder {
  jobId: string
}

export async function getProjectFiles (jobId: string) {
  await downloadFile(`/api/admin/jobs/${jobId}/files`)
}

export async function getJobLogs (jobId: string) {
  const response = await axios.get<string>(`/api/admin/jobs/${jobId}/logs`)
  return response.data
}

export async function getJob (jobId: string) {
  const response = await axios.get<ExtendedJobRecord>(`/api/admin/jobs/${jobId}`)
  return response.data
}

export async function exportJobs (params: object) {
  await downloadFile('/api/admin/jobs/export', params)
}

export async function cancelJob (jobId: string) {
  await axios.post(`/api/admin/jobs/${jobId}/cancel`)
}

export async function changeJobDeadline (jobId: string, deadline: string) {
  await axios.post(`/api/admin/jobs/${jobId}/changeDeadline`, deadline)
}

export async function changeJobRequester (jobsId: string, requesterId: string) {
  await axios.post(`/api/admin/jobs/${jobsId}/changeRequester`, jobsId, {
    params: { requesterId }
  })
}

// returns all attachments descriptions (if stageId is specified, will include attachments from this stage)
export async function allAttachmentsDescription (jobId: string, stageId?: string) {
  const response = await axios.get<UserFile[]>(`/api/workflow/workflow/attachments/jobs/${jobId}/attachments`, {
    params: { stageId }
  })

  return response.data
}

// returns all attachments descriptions (if stageId is specified, will include attachments from this stage)
export async function downloadJobAttachment (jobId: string, fileId: string, fileName?: string) {
  await downloadFile(`/api/workflow/workflow/attachments/jobs/${jobId}/attachments/${fileId}`, {}, fileName)
}

export async function sendReminder (jobReference: NewReminder) {
  await axios.post<void>(`/api/admin/zendesk/reminders`, jobReference)
}

export async function getComments (jobId: string) {
  const response = await axios.get<Comment[]>(`/api/workflow/workflow/requester/jobs/${jobId}/comments`)

  return response.data
}

export async function addComment (jobId: string, text: string) {
  await axios.post<Comment[]>(`/api/workflow/workflow/requester/jobs/${jobId}/comments`, text)
}
