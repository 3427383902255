import React, { useEffect, useState } from 'react'
import { Identifier, Loading, useDataProvider, useRecordContext } from 'react-admin'
import { getCompanyFinancialData, CompanyBillingInformation, CompanyRecord, PaypalMethod, BankMethod, IbanMethod, CompanyPaymentMethodVariant, getCompanyHourlyRateLimits, HourlyRateLimits } from 'api/company'
import { Box } from '@mui/material'
import ActionButton from 'components/ActionButton'
import { EditCompanyModal } from './companyModals/EditCompany'
import { InvoiceModal } from './companyModals/InvoiceModal'
import RoundedPanel, { CrossCheckbox, Label } from 'common/RoundedPanel'

import 'styles.scss'
import { handleEsc } from 'common/closeOnEscape'

interface Props {
  id?: Identifier
}

const CompanyBlock = ({ record, billingInfo, hourlyRateLimits }: { record: CompanyRecord, billingInfo: CompanyBillingInformation, hourlyRateLimits: HourlyRateLimits }) => {
  const [paramValue, setParamValue] = useState<number | null>(null)
  const dataProvider = useDataProvider()
  useEffect(() => {
    dataProvider
      .getOne(`features/parameters`, { id: `MinCompanyPaymentThreshold` })
      .then(({ data }) => { setParamValue(data.value) })
  }, [])

  const financialInfo = <>
    <Label noSelect>Company: </Label>{record.name}
    <hr />
    <Label>Financial info</Label>
    <Box height={13} />
    <Label noSelect>Vat: </Label>{billingInfo.financialInfo?.vatNum}<br />
    <Label noSelect>Currency: </Label>{billingInfo.financialInfo?.currency}<br />
    <Label>Country: </Label>{billingInfo.financialInfo?.address.country}<br />
    <Label>City: </Label>{billingInfo.financialInfo?.address.city}<br />
    <Label>Street: </Label>{billingInfo.financialInfo?.address.street} {billingInfo.financialInfo?.address.streetNum}<br />
    <Label>Postal Code: </Label>{billingInfo.financialInfo?.address.postalCode}
    <Box height={13} />
    <Label>Min allowed Hourly Rate ({hourlyRateLimits.currency}): </Label>{hourlyRateLimits.min}<br />
    <Label>Max allowed Hourly Rate ({hourlyRateLimits.currency}): </Label>{hourlyRateLimits.max}<br />
    <Box height={13} />
    <Label>Company payment threshold ({billingInfo.financialInfo?.currency || 'EUR'}): </Label>{billingInfo.financialInfo?.paymentThreshold || paramValue}<br />
    <Box height={13} />
    <CrossCheckbox checked={record.ngo} disableRipple />Accept Charity<br />
    <hr />
    <Label>Contact Person</Label>
    <Box height={13} />
    <Label>Name: </Label>{billingInfo.contactPerson?.name}<br />
    <Label>Surname: </Label>{billingInfo.contactPerson?.surname}<br />
    <Label>Email: </Label>{billingInfo.contactPerson?.mail}<br />
    <Label>Phone: </Label>{billingInfo.contactPerson?.phone}<br />
  </>

  return <RoundedPanel>
    {financialInfo}
  </RoundedPanel>
}

const PaymentBlock = ({ billingInfo }: { billingInfo: CompanyBillingInformation }) => {
  const PaypalDisplay = ({ isDefault, paypalUser }: PaypalMethod) => {
    return <>
      <CrossCheckbox checked={isDefault} disableRipple /><Label noSelect>PayPal</Label>
      <Box height={13} />
      <Label>PayPal email address: </Label>{paypalUser}<br />
    </>
  }
  const BankDisplay = ({ isDefault, accountNumber, bankName, bic, routingNumber }: BankMethod) => {
    return <>
      <CrossCheckbox checked={isDefault} disableRipple /><Label noSelect>Bank</Label>
      <Box height={13} />
      <Label>Account number: </Label>{accountNumber}<br />
      <Label>Bank name: </Label>{bankName}<br />
      <Label>SWIFT/BIC: </Label>{bic}<br />
      <Label>Routing number: </Label>{routingNumber}<br />
    </>
  }
  const IBanDisplay = ({ isDefault, bankName, iban, accountHolder }: IbanMethod) => {
    return <>
      <CrossCheckbox checked={isDefault} disableRipple /><Label noSelect>Bank (IBAN)</Label>
      <Box height={13} />
      <Label>IBAN: </Label>{iban}<br />
      <Label>Bank name: </Label>{bankName}<br />
      <Label>Account holder: </Label>{accountHolder}<br />
    </>
  }

  const MethodDisplay = (m: CompanyPaymentMethodVariant) => {
    /* eslint-disable react/destructuring-assignment */
    if ('paypalPayment' in m) {
      return PaypalDisplay(m.paypalPayment)
    }
    if ('bankPayment' in m) {
      return BankDisplay(m.bankPayment)
    }
    if ('ibanPayment' in m) {
      return IBanDisplay(m.ibanPayment)
    }
    console.warn('Unknown payment method', m)
    return <>Unknown payment method</>
    /* eslint-enable react/destructuring-assignment */
  }

  const methods = billingInfo.paymentMethods.map((method, index) => {
    return <Box>
      <MethodDisplay {...method} />
      {index !== billingInfo.paymentMethods.length - 1 && <hr />}
    </Box>
  })

  const block = <>
    <Label>Payment methods</Label>
    <hr />
    {
      methods && methods.length > 0 ? methods : <Label>No payment methods</Label>
    }
  </>

  return <RoundedPanel>{block}</RoundedPanel>
}

type CurrentModal = "EDIT_COMPANY" | "GENERATE_INVOICE" | null
const Profile = ({ id }: Props) => {
  const record = useRecordContext<CompanyRecord>()

  const [billingInfo, setBillingInfo] = useState<CompanyBillingInformation | undefined>(undefined)
  const [hourlyRateLimits, setHourlyRateLimits] = useState<HourlyRateLimits | undefined>(undefined)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState<CurrentModal | null>(null)

  const closeModal = () => { setOpen(null) }

  useEffect(() => {
    if (!id) return

    getCompanyFinancialData(id as string)
      .then((response) => {
        setBillingInfo(response)
      })
      .catch(() => {
        setError(true)
      })

    getCompanyHourlyRateLimits(id as string)
      .then((response) => {
        setHourlyRateLimits(response)
      })
  }, [id])

  React.useEffect(handleEsc(closeModal), [])

  if (!record) return null

  const isLoading = !!billingInfo && !!hourlyRateLimits

  return (<>
    <EditCompanyModal
      record={record}
      open={open === "EDIT_COMPANY"}
      onClose={closeModal}
      billingInfoFilled={!!billingInfo?.financialInfo}
    />
    <InvoiceModal
      record={record}
      open={open === "GENERATE_INVOICE"}
      onClose={closeModal}
    />
    <Box className="edit-tab">
      <Box className='on-left'>
        <ActionButton
          onClick={() => {
            setOpen("EDIT_COMPANY")
          }}
        >Edit</ActionButton>
        <ActionButton
          onClick={() => {
            setOpen("GENERATE_INVOICE")
          }}
        >Generate Invoice</ActionButton>
      </Box>
    </Box>
    <Box className="panel">
      <Box width="600px">
        {error ? <Label>Error loading data</Label> : !isLoading ? <Loading /> : <CompanyBlock record={record} billingInfo={billingInfo} hourlyRateLimits={hourlyRateLimits} />}
      </Box>
      <Box width="600px">
        {error ? <Label>Error loading data</Label> : !isLoading ? <Loading /> : <PaymentBlock billingInfo={billingInfo} />}
      </Box>
    </Box>
  </>
  )
}

export default Profile